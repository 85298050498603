<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('transitions')"
                        @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div class="mb-1"
                             v-for="(column, key) in datatable.columns"
                             v-bind:key="key">
                            <b-form-checkbox v-model="column.hidden"
                                             :id="'checkbox-' + key"
                                             :name="'checkbox-' + key"
                                             :value="false"
                                             :unchecked-value="true"
                                             v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('transitions')"
                              :isNewButton="checkPermission('staff_store')"
                              :isColumns="true"


                >
                    <template v-slot:columns>
                        <div class="mb-1"
                             v-for="(column, key) in datatable.columns"
                             v-bind:key="key">
                            <b-form-checkbox v-model="column.hidden"
                                             :id="'checkbox-' + key"
                                             :name="'checkbox-' + key"
                                             :value="false"
                                             :unchecked-value="true"
                                             v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>

            <!-- Datatable -->
            <datatable-filter
                :export-excel="checkPermission('staff_excelexport')"
                @exportExcel="downloadExcel"
                @filter="filter" @filterClear="filterClear"
                v-show="datatable.filterStatus">
                    <b-row>
                        <b-col class="d-flex">
                            <b-col >
                                <b-form-group :label="$t('key')">
                                    <b-form-input type="text"
                                                  v-model="datatable.queryParams.filter.key"
                                                  :placeholder="$t('key')"/>
                                </b-form-group>
                            </b-col>
                    </b-col>
                </b-row>

            </datatable-filter>

            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                       @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>

            <CommonModal ref="edit" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" >
                <template v-slot:CommonModalTitle>
                    {{ $t("edit").toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <UpdateForm v-if="formProgress=='edit'" :formId="formId" @updated="updateSuccess"/>
                </template>
            </CommonModal>


        </app-layout>
    </div>
</template>
<script >

import qs from "qs";
import TranslationService from "../../../services/TranslationService";
import UpdateForm from "./UpdateForm.vue"

export default {
    components: {UpdateForm},
    data() {
        return {
            formProgress:null,
            formId:null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('edit'),
                                class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "translation_update",
                                callback: (row) => {
                                    this.updateFormShow(row.id);
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                permission: "translation_delete",
                                callback: (row) => {
                                    this.deleteForm(row.id);
                                }
                            },


                        ]
                    },
                    {
                        label: this.toUpperCase('group'),
                        field: 'group',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('key'),
                        field: 'key',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('tr_name'),
                        field: 'text.tr',
                        hidden: false,
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('en_name'),
                        field: 'text.en',
                        hidden: false,
                        sortable: false
                    },

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
        }
    },
    metaInfo() {
        return {
            title: this.$t('transitions')
        }
    },
    methods:{
        formClear() {
            this.formId = null
            this.formProgress = null
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, { encode: false }),
            };
            return TranslationService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        updateFormShow(id){
            this.formProgress = "edit"
            this.formId = id
            this.$refs.edit.$refs.commonModal.show();
        },
        updateSuccess(){
           this.formProgress=null
            this.formId=null
            this.$refs.edit.$refs.commonModal.hide();
           this.filter()
        },
        deleteForm(id){
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        TranslationService.del(id)
                            .then(res => {
                                this.$toast.success(res.data.message)
                                this.getRows()
                            }).catch(err => {
                            this.showErrors(err)
                        })
                    }
                })
        },

        downloadExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }

            TranslationService.excelExport(config).then(res => {
                this._downloadFile(res, this.$t('transitions') + '.xlsx')
            }).catch(err => this.showErrors(err))
        }
    }
}

</script>
